import { useRef, useEffect, useState } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { ImageBlock, ImageBlockType } from '../imageBlock/ImageBlock';
import throttle from 'lodash.throttle';

import style from './VideoBlock.module.scss';

type VideoJSPlayerType = {
  options: VideoJsPlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
  onVideoPlay?: () => void;
  onVideoPause?: () => void;
  onVideoEnd?: () => void;
  onVideoProgress?: () => void;
  playsInline: boolean;
};

//  Baed on https://docs.videojs.com/tutorial-react.html

export const VideoJSPlayer = ({
  options,
  onReady,
  onVideoPlay = () => {},
  onVideoPause = () => {},
  onVideoEnd = () => {},
  onVideoProgress = () => {},
  playsInline = true,
}: VideoJSPlayerType): JSX.Element => {
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);

  const [showPoster, setShowPoster] = useState<boolean>(true);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      onVideoPause();

      const player = (playerRef.current = videojs(
        videoElement,
        // remove the poster, we'll add our own
        { ...options, poster: undefined },
        () => {
          onReady && onReady(player);
        },
      ));
      player.on('ended', () => {
        setShowPoster(true);
        onVideoEnd && onVideoEnd();
      });

      const throttledVideoProgress = throttle(() => {
        onVideoProgress();
      }, 1000);

      player.on('timeupdate', throttledVideoProgress);
      player.on('touchstart', () => {
        if (player.paused()) {
          try {
            player.play();
          } catch {}
        } else {
          player.pause();
        }
      });
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      const current = player.currentSrc();
      const isAlreadyPlaying = options.sources?.some(({ src }: any) => src === current);
      // avoid re-renders if we are already playing the correct file
      if (!isAlreadyPlaying) {
        player.src(options.sources);
      }
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const posterState = showPoster ? style.posterShowing : style.posterHiding;
  const posterOptions: ImageBlockType = {
    desktop: {
      src: options.poster || '',
      layout: 'fill',
      objectFit: 'cover',
      className: `${style.poster} ${posterState}`,
    },
    alt: '',
  };

  return (
    <div className={style.container}>
      <ImageBlock {...posterOptions} alt={''} />
      <div className={style.video}>
        <div data-vjs-player>
          <video
            ref={videoRef}
            playsInline={playsInline}
            onPlay={() => {
              setShowPoster(false);
              onVideoPlay();
            }}
            onPause={onVideoPause}
            className=" video-js vjs-show-big-play-button-on-pause"
          />
        </div>
      </div>
    </div>
  );
};
