import Image, { ImageProps } from 'next/image';
import { useState } from 'react';
import style from './ImageBlock.module.scss';

export type BlockImageProps = ImageProps & {};

export type ImageBlockType = {
  desktop: BlockImageProps;
  mobile?: BlockImageProps;
  alt: string;
  title?: string;

};
//  docs: https://nextjs.org/docs/basic-features/image-optimization and https://nextjs.org/docs/api-reference/next/image

export const ImageBlock = ({ desktop, mobile, alt = '', title=undefined }: ImageBlockType): JSX.Element => {
  const [isDesktopLoaded, setIsDesktopLoaded] = useState<boolean>(false);
  const [isMobileLoaded, setIsMobileLoaded] = useState<boolean>(false);

  const onDesktopLoadingComplete = () => {
    setIsDesktopLoaded(true);
  };
  const onMobileLoadingComplete = () => {
    setIsMobileLoaded(true);
  };
  const mobileLoadState = isMobileLoaded ? style.imageLoaded : null;
  const desktopLoadState = isDesktopLoaded ? style.imageLoaded : null;

  const canSwap = mobile && desktop;
  const desktopClass = canSwap ? style.desktopImageCanSwap : style.desktopImage;


  return (
    <>
      {mobile && (
        <Image
          className={`${style.mobileImage} ${mobileLoadState} block-image-mobile`}
          {...mobile}
          alt={alt}
          title={title}
          onLoadingComplete={onMobileLoadingComplete}
          draggable={false}
          blurDataURL={mobile.blurDataURL}
          placeholder={mobile.blurDataURL ? 'blur' : undefined}
        />
      )}
      <Image
        className={`${desktopClass} ${desktopLoadState} block-image-desktop`}
        {...desktop}
        blurDataURL={desktop.blurDataURL}
        draggable={false}
        placeholder={desktop.blurDataURL ? 'blur' : undefined}
        alt={alt}
        title={title}
        onLoadingComplete={onDesktopLoadingComplete}
      />
    </>
  );
};
